import React from "react";
import { styles } from '../../styles';
import { checkbox, checkboxFirst } from "../../assets";


const ListSearch = ({ data, selectedItems, handleItemClick, showList, mode, onClick }) => {


    return (
        <div>
            <div onClick={onClick} className={`${showList ? mode === 'primary' ? styles.primaryBackColor : styles.secondaryBackColor : null} px-5 flex-col flex flex-auto justify-center h-full`}>
                <label className={`${mode === 'primary' ? showList ? styles.heroFilterTextSecond : styles.heroFilterText : showList ? styles.heroFilterText : styles.heroFilterTextSecond} libre-baskerville-regular rounded-l-full text-start`}>
                    {data.name}
                </label>
                <p className={`${mode === 'primary' ? showList ? styles.heroFilterTextSecond : styles.heroFilterText : showList ? styles.heroFilterText : styles.heroFilterTextSecond} bg-transparent outline-none inter-thin text-start`}>
                    {data.subname}</p>
            </div>
            {showList && (
                <div className={`${mode === 'primary' ? styles.primaryBackColor : styles.secondaryBackColor} absolute flex flex-col rounded-sm p-3`}>
                    {data.list_values && data.list_values.map((value, index) => (
                        <div key={index} onClick={() => handleItemClick(value)} className="cursor-pointer flex items-center pt-3">
                            <div className={`${mode === 'primary' ? 'border-[#5A5C20]' : 'border-[#FFFEFA]'} flex items-center justify-center border-[1px] rounded-sm w-5 h-5`}>
                                {selectedItems.includes(value) ? (
                                    <img src={`${mode === 'primary' ? checkbox : checkboxFirst}`} className="h-2" />
                                ) : null}
                            </div>
                            <p className={`${mode === 'primary' ? styles.secondaryTextColor : styles.primaryTextColor} ${styles.regularText} inter-thin pl-2`}>{value}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ListSearch
