import React from "react";
import { styles } from '../../styles';
import { moins, plus } from "../../assets";


const NumberFilter = ({ filter, counters, increment, decrement }) => {


    return (
        <div className='absolute flex flex-col border-[1px] border-[#5A5C20] bg-[#FFFEFA] rounded-sm p-3'>
            {filter.number_values && filter.number_values.map((value, index) => (
                <div key={index} className='flex justify-between items-center min-w-full py-1 gap-3'>
                    <div className='flex'>
                        <p className={`${styles.secondaryTextColor} ${styles.regularText} inter-thin `}>{value.name}</p>
                    </div>
                    <p className={`${styles.secondaryTextColor} ${styles.smText} inter-extra-thin`}>{value.description}</p>
                    <div className='flex items-end justify-end'>
                        <div className='flex gap-3 items-center '>
                            <div onClick={() => decrement(index)} className="rounded-full border-[#5A5C20] border-[1px] h-5 w-5 flex items-center justify-center">
                                <img src={moins} />
                            </div>
                            <p className={`${styles.secondaryTextColor} ${styles.regularText} w-[10px] inter-thin`}>{counters[index]}</p>
                            <div onClick={() => increment(index)} className="rounded-full border-[#5A5C20] border-[1px] h-5 w-5 flex items-center justify-center">
                                <img src={plus} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default NumberFilter
