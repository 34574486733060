import React from 'react';
import { styles } from "./../../styles";
import { useTranslation } from 'react-i18next';


const LocationCard = ({ location, onClick }) => {
    const { t } = useTranslation();
    const toUpperCase = (text) => {
        return text.toUpperCase();
    };

    return (
        <>
            {location && location.map((data, index) => (
                <div key={`address-${index}`} onClick={() => onClick(data, index)}>
                    <div className="cursor-pointer flex flex-col w-[305px] pb-10">
                        <p className={`${styles.thirdTextColor} ${styles.locationTitle} inter-bold text-start pb-2`}>{toUpperCase(data.name)}</p>
                        {data.sm_picture ? (
                            <img src={data.sm_picture} alt="photo du lieu" className='h-[300px] w-[300px] object-cover' />
                        ) : null}
                        <div className="pt-4">
                            <p className={`${styles.thirdTextColor} ${styles.locationSubTitle} text-start libre-baskerville-regular-italic`}>{data.subname}</p>
                            <p className={`${styles.thirdTextColor} ${styles.locationSubTitle} text-start inter-thin pt-2`}>{t('location.firstprice.1')}{data.firstPrice}{t('location.firstprice.2')}</p>
                            <div className="flex pt-2">
                                <p className={`${styles.thirdTextColor} ${styles.locationSubTitle} text-start inter-thin mr-2`}>{data.city}</p>
                                <span className={`border-l border-[#5A5C20]`} />
                                <p className={`${styles.thirdTextColor} ${styles.locationSubTitle} text-start inter-thin mx-2`}>{data.nb} {data.nb === 1 ? 'personne' : 'personnes'}</p>
                                <span className={`border-l border-[#5A5C20] `} />
                                <p className={`${styles.thirdTextColor} ${styles.locationSubTitle} text-start inter-thin mx-2`}>{data.type}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>

    )
}

export default LocationCard
