
import '../App.css';
import React, { useEffect, useState } from "react";
import { logo, backHome, backNewsPC } from '../assets';
import { styles } from "../styles";
import SearchBar from '../components/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import Suggestion from '../components/Suggestion';
import Favorite from '../components/Favorite';
import {
    useNavigate,
} from 'react-router-dom';

import axios from 'axios';

import axiosInstance from '../context/axiosConfig';

function HomePage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(0);
    const [destination, setDestination] = useState('');
    const [event, setEvent] = useState('');
    const [traveler, setTraveler] = useState('');
    const [data, setData] = useState([]);
    const [suggestionData, setSuggestionData] = useState([]);

    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');


    useEffect(() => {
        setScreenWidth(window.innerWidth);
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
        return () =>
            window.removeEventListener("resize", () =>
                setScreenWidth(window.innerWidth)
            );
    }, []);


    function searchCall() {
        //console.log('Nav props : ', destination, traveler, event);
        navigate('/addresses', { state: { destination, traveler, event } });
        window.scrollTo(0, 0);
    }

    function handleUpdate(newDestination, newTraveler, newEvent) {
        setDestination(newDestination);
        setEvent(newEvent);
        setTraveler(newTraveler);
    }


    useEffect(() => {
        axiosInstance.get('/api/filter/')
            .then(res => {
                setData(res.data);
                //console.log('response data:', res.data);
            })
            .catch(err => {
                console.error('Error fetching filter data:', err);

                //console.log('response data:', res.data);
            })

    }, []);

    

    function goToHandler(data, index) {
        //console.log('Nav props : ', data.id, index);
        navigate(`/addresses/${data.id}`, { state: { data, index } });
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        axiosInstance.get('/api/addresses/')
            .then(res => {
                // Filter the favorite addresses
                const favoriteAddresses = res.data.filter(address => address.is_favorite === true);
                setSuggestionData(favoriteAddresses);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    
    return (
        <><div className="App flex-col ">
            <div>
                <img src={backHome} alt="background picture" className={`h-screen absolute w-full object-cover`} />
                <div className={`${screenWidth < 730 ? "pt-20" : null} h-screen flex justify-center items-center`}>
                    <div className={`${styles.padding} max-w-7xl mx-auto relative z-0 flex flex-col justify-center`}>
                        <img className='h-[60px] sm:h-[60px] md:h-[90px]' src={logo} alt="logo" />
                        <h1 className={`${styles.heroHeadText} text-center w-full libre-baskerville-regular mt-5`}>
                            {t('home.text.slogan.1')}<span className='libre-baskerville-regular-italic mx-2'>{t('home.text.slogan.2')}</span>{t('home.text.slogan.3')}<span className='libre-baskerville-regular-italic mx-2'>{t('home.text.slogan.4')}</span>
                        </h1>
                        <div className='pt-10 md:pt-10'>
                            <h2 className={`${styles.heroSubText} libre-baskerville-regular pb-6`}>{t('home.text.destination')}</h2>
                            <SearchBar
                                mode={'primary'}
                                filter={data}
                                onClick={searchCall}
                                onUpdate={(d, e, t) => handleUpdate(d,e,t)}
                                destination={destination}
                                event={event}
                                traveler={traveler}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Suggestion location={suggestionData} onClick={goToHandler}/>
            <Favorite />
            </div>
        </>
    );

}

export default HomePage;


