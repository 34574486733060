import React from "react";
import { styles } from '../../styles';
import { useTranslation } from 'react-i18next';



const SummaryList = ({ data }) => {
    const toUpperCase = (text) => {
        return text.toUpperCase();
    };
    const { t } = useTranslation();


    return (
        <div className=' flex flex-col sm:flex-row py-10 gap-10 sm:gap-20 md:gap-40'>
            {data.type && (
            <div className='sm:w-1/3 text-start flex flex-col '>
                <div className='max-w-[300px]'>
                    <p className={`${styles.thirdTextColor} ${styles.sectionSubTitle} inter-thin text-start py-2`}>{toUpperCase(data.type)}</p>
                    <hr className="border-t border-[#5A5C20]" />

                    <p className={`${styles.thirdTextColor} ${styles.sectionSubTitle} inter-thin text-start py-2`}>{data.nb} {toUpperCase(data.nb === 1 ? t('location.nb.1') : t('location.nb.2'))}</p>
                    {data.bedroom || data.bed ? (
                        <hr className="border-t border-[#5A5C20]" />
                    ) : null}
                    {data.bedroom &&
                        <>
                            <p className={`${styles.thirdTextColor} ${styles.sectionSubTitle} inter-thin text-start py-2`}>{data.bedroom} {toUpperCase(data.bedroom === 1 ? t('location.nb.bedroom.1') : t('location.nb.bedroom.2'))}</p>
                            <hr className="border-t border-[#5A5C20]" />
                        </>
                    }
                    {data.bed &&
                        <p className={`${styles.thirdTextColor} ${styles.sectionSubTitle} inter-thin text-start py-2`}>{data.bed} {toUpperCase(data.bed === 1 ? t('location.nb.bed.1') : t('location.nb.bed.2'))}</p>
                    }
                </div>
            </div>
            )}
            <div className='flex sm:w-2/3 w-full'>
                <p className={`${styles.thirdTextColor} ${styles.regularText}  w-full libre-baskerville-regular text-end sm:text-start pt-2`}>
                    {data.description}
                </p>
            </div>
        </div>
    );
}

export default SummaryList