import React, { useState } from 'react';
import axios from 'axios';
import { styles } from '../styles';
import { logo } from '../assets';
import axiosInstance from './../context/axiosConfig';


function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosInstance.post('/api/token/', { 
                email: email,
                password: password, 
            });

            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);

            window.location.href = '/';
        } catch (err) {
            if (err.response && err.response.data) {
                setError(err.response.data.detail || 'Invalid credentials');
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <div className='h-screen bg-[#5A5C20] flex flex-col justify-center items-center py-10'>
            <img src={logo} alt="Logo"/>
            <h2 className={`${styles.primaryTextColor} ${styles.sectionTitle} libre-baskerville-regular m-10 max-w-[500px] text-center md:text-start`}>Login Page</h2>
            <form className="min-w-[300px] flex flex-col gap-3" onSubmit={handleSubmit}>
                <div className='flex flex-col w-full'>
                    <label className={`${styles.primaryTextColor} inter-regular text-center md:text-start pb-1`}>Email</label>
                    <input
                        className='border-2 border-[#5A5C20] rounded-lg h-[50px]'
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='flex flex-col'>
                    <label className={`${styles.primaryTextColor} inter-regular text-center md:text-start pb-1`}>Mot de passe</label>
                    <input
                        type="password"
                        className='border-2 border-[#5A5C20] rounded-lg h-[50px]'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {error && <p>{error}</p>}
                <div className='pt-3'>
                    <button className={`${styles.primaryBackColor} ${styles.secondaryTextColor} inter-bold h-[50px] min-w-[300px] rounded-lg`} type="submit">Connexion</button>
                </div>
            </form>
        </div>
    );
}

export default Login;
