import React from "react";
import { styles } from '../../styles';
import { checkbox } from "../../assets";


const ListFilter = ({ filter, selectedItems, handleItemClick }) => {


    return (
        <div className='absolute flex flex-col text-start border-[1px] bg-[#FFFEFA] border-[#5A5C20] rounded-sm pb-3 px-3'>
            {filter.list_values && filter.list_values.map((value, index) => (
                <div key={index} onClick={() => handleItemClick(value)} className="cursor-pointer flex items-center pt-3">
                    <div className="flex items-center justify-center border-[1px] border-[#5A5C20] rounded-sm min-w-5 min-h-5 ">
                        {selectedItems.includes(value) ? (
                            <img src={checkbox} className="h-2 w-2" />
                        ) : null}
                    </div>
                    <p className={`${styles.secondaryTextColor} ${styles.regularText} inter-thin pl-2`}>{value}</p>
                </div>
            ))}
        </div>
    );
}

export default ListFilter
