import React, { useState } from 'react';
import { styles } from './../../styles';
import LogoText from './LogoText';
import { fb, linked, insta, pinter, arrowRight } from './../../assets';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../context/axiosConfig';


const Footer = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async () => {
        if (!email || email.indexOf('@') === -1) {
            setMessage(t('footer.incorectemail'));
            return;
        }
        try {
            const response = await axiosInstance.post('/api/newsletter/', { email: email });
            setMessage(t('footer.emailsend'));
            setEmail('');
        } catch (err) {
            console.error('Error posting email data:', err);
            setMessage(t('footer.emailerror'));
        }
    };

    return (
        <div className={`${styles.secondaryBackColor} pt-10 bottom-0 flex flex-col `} >
            <div className='pb-10 max-w-7xl mx-auto relative flex flex-col justify-center'>
                <LogoText />
                <p className={`${styles.heroHeadText} flex justify-center libre-baskerville-regular mt-10`}>{t('footer.news.1')}
                    <span className='libre-baskerville-regular-italic mx-1'>
                        {t('footer.news.2')}
                    </span>
                    {t('footer.news.3')}
                </p>
                <div className='border-[1px] border-[#F7F3E6] rounded-full h-[50px] md:h-[60px] mt-7 flex items-center justify-between pl-8'>
                    <input
                        type="email"
                        placeholder={t('footer.news.placeholder')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`${styles.primaryTextColor} placeholder:text-[#FFFEFA] text-[#FFFEFA] libre-baskerville-regular bg-transparent outline-none w-full`}
                    />
                    <div className={`${styles.primaryBackColor} h-full flex items-center justify-center px-[35px] md:px-[50px] rounded-full`}>
                        <button
                            onClick={handleSubmit}
                            className=' flex items-center justify-center'>
                            <p className={`${styles.regularText} ${styles.thirdTextColor} libre-baskerville-regular-italic`}>{t('footer.news.button')}</p>
                        </button>
                    </div>
                </div>
                <div className='relative flex items-center justify-center mt-5'>
                    {message && <p className={`${styles.smText} ${styles.primaryTextColor} absolute bottom-[-20px] libre-baskerville-regular`}>{message}</p>}
                </div>
                

            </div>


            <hr className={`mb-4 border-t ${styles.primaryBackColor} max-w-7xl w-full mx-auto`} />

            <div className='flex flex-grid grid grid-cols-1 md:grid-cols-3 gap-y-10 px-10 justify-center pb-10'>

                <div className='col-span-1 flex flex-col  items-center md:items-start md:px-0 lg:px-10 md:order-first'>
                    <p className={`${styles.regularText} ${styles.primaryTextColor} libre-baskerville-regular text-center md:text-start`}>
                        {t('footer.textleft.1')}
                        <span className='libre-baskerville-regular-italic mx-1'>
                            {t('footer.textleft.2')}
                        </span>
                        {t('footer.textleft.3')}
                    </p>
                    <p className={`${styles.regularText} ${styles.primaryTextColor} libre-baskerville-regular text-center md:text-start`}>
                        {t('footer.textleft.4')}
                        <span className='libre-baskerville-regular-italic mx-1'>
                            {t('footer.textleft.5')}
                        </span>
                        {t('footer.textleft.6')}
                    </p>
                    <p className={`${styles.regularText} ${styles.primaryTextColor} libre-baskerville-regular text-center md:text-start`}>
                        {t('footer.textleft.7')}
                    </p>
                </div>

                <div className='col-span-1 flex md:flex-col justify-center md:px-0 lg:px-10'>
                    <div className="flex justify-center">
                        <a href="https://www.instagram.com/thehost.place/" target="_blank" rel="noopener noreferrer">
                            <img className="h-[25px] w-[25px] " src={insta} alt="Instagram Logo" />
                        </a>
                        <a href="https://www.instagram.com/thehost.place/" target="_blank" rel="noopener noreferrer">
                            <img className="h-[25px] w-[25px] ml-5" src={fb} alt="Facebook Logo" />
                        </a>
                        <a href="https://www.instagram.com/thehost.place/" target="_blank" rel="noopener noreferrer">
                            <img className="h-[25px] w-[25px] ml-5" src={linked} alt="LinkedIn Logo" />
                        </a>
                        <a href="https://www.instagram.com/thehost.place/" target="_blank" rel="noopener noreferrer">
                            <img className="h-[25px] w-[25px] ml-5" src={pinter} alt="Pinterest Logo" />
                        </a>
                    </div>
                </div>
                <div className='col-span-1 flex flex-col items-center md:items-end md:px-0 lg:px-10 '>
                    <p className={`${styles.regularText} ${styles.primaryTextColor} libre-baskerville-regular  text-center md:text-end`}>
                        {t('footer.textright.1')}
                        <span className='libre-baskerville-regular-italic mx-1'>
                            {t('footer.textright.2')}
                        </span>
                        {t('footer.textright.3')}
                    </p>
                    <p className={`${styles.regularText} ${styles.primaryTextColor} libre-baskerville-regular text-center md:text-end`}>
                        {t('footer.textright.4')}
                        <span className='libre-baskerville-regular-italic mx-1'>
                            {t('footer.textright.5')}
                        </span>
                        {t('footer.textright.6')}
                    </p>
                    <p className={`${styles.regularText} ${styles.primaryTextColor} libre-baskerville-regular text-center md:text-end`}>
                        {t('footer.textright.7')}
                    </p>
                </div>
            </div>
            <div className="w-full flex justify-center items-center pt-10 md:pt-5 pb-5">
                <p className={`${styles.smText} ${styles.primaryTextColor} flex justify-center inter-thin text-center`}>
                    {t('footer.copyright.1')}
                </p>
                <p className={`${styles.smText} ${styles.primaryTextColor} flex justify-center inter-thin text-center ml-1`}>
                    {t('footer.copyright.2')}
                </p>
                <p className={`${styles.smText} ${styles.primaryTextColor} flex justify-center inter-thin text-center ml-1`}>
                    {t('footer.copyright.3')}
                </p>
                <p className={`${styles.smText} ${styles.primaryTextColor} flex justify-center inter-thin text-center ml-1`}>
                    {t('footer.copyright.4')}
                </p>
                <p className={`${styles.smText} ${styles.primaryTextColor} flex justify-center inter-thin text-center ml-1`}>
                    {t('footer.copyright.5')}
                </p>
            </div>
        </div>
    )
}

export default Footer
