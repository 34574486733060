import { Outlet } from 'react-router-dom';
import React from "react";
import Navbar from '../components/UI/NavBar';
import Footer from '../components/UI/Footer';


function RootLayout() {


  return (
    <div>
      <div className='h-full justify-center align-center overflow-hidden' >
        <Navbar/>
        <Outlet />
        <Footer/>

      </div>

    </div>
  );
}

export default RootLayout;
