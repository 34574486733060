import React from "react";
import { styles } from "../styles";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';






const SuggestionCard = ({ image, name, description, location, nb, price, type }) => {
    const { t } = useTranslation();


    return (
        <div className="cursor-pointer flex flex-col min-w-[305px] w-[305px] pr-0 pb-5 md:pb-0 md:pr-5">
            <img src={image} alt="suggestion address picture" className='h-[370px] w-[350px] object-cover' />
            <div className="pt-4">
                <p className={`${styles.secondaryTextColor} ${styles.locationTitle} inter-bold text-start`}>{name}</p>
                <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start libre-baskerville-regular-italic pt-2`}>{description}</p>
                <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin pt-2`}>{t('location.firstprice.1')}{price}{t('location.firstprice.2')}</p>
                <div className="flex pt-2">
                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mr-2`}>{location}</p>
                    <span className={`border-l border-[#5A5C20]`} />
                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mx-2`}>{nb} {nb === 1 ? t('location.nb.1') : t('location.nb.2')}</p>
                    <span className={`border-l border-[#5A5C20] `} />
                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mx-2`}>{type}</p>
                </div>
            </div>
        </div>
    );
};




const Suggestion = ({location, onClick}) => {
    const { t } = useTranslation();
    const toUpperCase = (text) => {
        return text.toUpperCase();
    };

    return (

        <div className={`sm:pt-16 pt-6 flex flex-col md:flex-row justify-center items-center`}>

            <div className={`${styles.paddingX} mx-auto flex-col items-start pb-6 pb-10 md:pb-0 pt-10 sm:pt-0`}>
                <div className=" flex flex-col justify-center ">
                    <h2 className={`${styles.secondaryTextColor} ${styles.secondSectionTitle} md:max-w-[250px] inter-bold text-center md:text-start`}>
                        {toUpperCase(t('suggestion.title'))}
                    </h2>
                    <div className="flex flex-wrap">
                        <p className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} libre-baskerville-regular text-center md:text-start`}>
                            {t('suggestion.subtitle.1')}
                            <span className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} libre-baskerville-regular-italic text-start mx-1`}>
                                {t('suggestion.subtitle.2')}
                            </span>
                            {t('suggestion.subtitle.3')}
                        </p>
                    </div>
                </div>
            </div>

            <div className={` mx-auto flex-col items-end overflow-x-auto hide-scrollbar`}>
                <div className="flex flex-wrap justify-center md:justify-between md:flex-nowrap gap-3 md:gap-0">
                    {location.map((data, index) => (
                        <div key={`address-${index}`} onClick={() => onClick(data, index)}>
                            <SuggestionCard
                                key={index}
                                image={data.sm_picture}
                                name={data.name}
                                description={data.subname}
                                location={data.city}
                                nb={data.nb}
                                type={data.type}
                                price={data.firstPrice}
                            />
                        </div>

                    ))}
                </div>
            </div>
        </div>

    );
};

export default Suggestion;
