import React from "react";
import { styles } from '../../styles';
import { useTranslation } from 'react-i18next';




const Equipments = ({ data }) => {

    const { t } = useTranslation();
    const toUpperCase = (text) => {
        return text.toUpperCase();
    };

    return (
        <>
            {data.equipements && data.equipments.length > 0 ? (
                <div className="py-10">
                    <div className='flex flex-wrap items-start justify-center'>
                        <div className="flex flex-wrap justify-between w-full">
                            <div className='w-2/5'>
                                <h2 className={`${styles.secondaryTextColor} ${styles.secondSectionTitle} inter-bold text-start`}>
                                    {t('detailocation.title.equipments')}
                                </h2>
                            </div>
                            <div className='w-3/5 max-h-[700px] sm:max-h-[1000px] md:max-h-[180px] flex flex-col flex-wrap'>
                                {data.equipments && data.equipments.map((equipement, index) => {
                                    const isLastColumn = Math.floor(index / 5) === Math.floor((data.equipments.length - 1) / 5);
                                    return (
                                        <div key={index} className={!isLastColumn ? 'pr-0 md:pr-10' : 'pr-0 sm:pr-0 md:pr-0'}>
                                            <p className={`${styles.thirdTextColor} ${styles.sectionSubTitle} text-start inter-thin py-1`}>{toUpperCase(equipement.name)}</p>
                                            <hr className="border-t border-[#363535]" />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </div>

                    {/*<div className='flex items-start justify-center py-10'>
                        <div className='w-2/5'>
                            <h2 className={`${styles.secondaryTextColor} ${styles.secondSectionTitle} inter-bold text-start`}>
                                {t('detailocation.title.equipments')}
                            </h2>
                        </div>
                        <div className='w-3/5 pt-3 max-h-[130px] flex flex-col flex-wrap '>
                            {data.equipments && data.equipments.map((equipement, index) => {

                                const columnIndex = Math.floor(index / 3);
                                const isLastInColumn = (index + 1) % 3 === 0 || index === data.equipments.length - 1;

                                return (
                                    <div key={index} className='mx-10'>
                                        <p className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} text-start inter-thin py-1`}>{toUpperCase(equipement.name)}</p>
                                        {!isLastInColumn && <hr className="border-t border-[#5A5C20]" />}
                                    </div>
                                );
                            })}
                        </div>
                        </div>*/}

                </div>
            ) : null}
        </>
    );
}

export default Equipments