
import '../App.css';
import React from "react";
import { headerBack } from '../assets';
import { styles } from "../styles";
import { useTranslation } from 'react-i18next';


function JournalPage() {
    const { t } = useTranslation();


    return (
        <>
            <div className="App flex-col">
                <img src={headerBack} alt="back" className='top-0 absolute h-screen w-full object-cover' />
                <div className={`${styles.padding}  max-w-7xl mx-auto relative z-0 flex flex-col justify-center`}>
                    <p className={`${styles.heroHeadText} libre-baskerville-regular mt-5`}>
                        {t('journal.title')}
                    </p>
                </div>
                <div className='bg-[#FFFEFA] h-[700px] w-full'>

                </div>
            </div>
        </>
    );
}

export default JournalPage;
