import React, { useEffect, useState, useRef } from "react";
import { styles } from "../../styles";
import ListFilter from "./ListFilter";
import NumberFilter from "./NumberFilter";







const FilterList = ({ filter, onClick, isActive, onSelect }) => {

    const [selectedItems, setSelectedItems] = useState([]);
    const [counters, setCounters] = useState(filter.number_values.map(() => 0));
    const ref = useRef(null);

    const handleItemClick = (item) => {
        setSelectedItems((prevSelectedItems) => {
            let updatedSelectedItems;
            if (prevSelectedItems.includes(item)) {
                updatedSelectedItems = prevSelectedItems.filter((i) => i !== item);
            } else {
                updatedSelectedItems = [...prevSelectedItems, item];
            }
            return updatedSelectedItems;
        });
    };

    const toUpperCase = (text) => {
        return text.toUpperCase();
    };

    useEffect(() => {
        const updatedCounters = counters.map(value => (value === 0 ? null : value));
        const hasNonZeroCounters = updatedCounters.some(value => value !== null);

        if (hasNonZeroCounters) {
            onSelect(filter.name, updatedCounters);
        } else if (selectedItems.length > 0) {
            onSelect(filter.name, selectedItems);
        } else {
            onSelect(filter.name, []);
        }
    }, [counters, selectedItems]);



    const increment = (index) => {
        setCounters((prevCounters) => {
            const newCounters = [...prevCounters];
            newCounters[index] += 1;
            return newCounters;
        });
    };

    const decrement = (index) => {
        setCounters((prevCounters) => {
            const newCounters = [...prevCounters];
            newCounters[index] = Math.max(0, newCounters[index] - 1);
            return newCounters;
        });
    };



    return (
        <div ref={ref}>
            <div onClick={onClick}>
                <div className={`${isActive ? 'bg-[#5A5C20]' : null} flex  items-center justify-center cursor-pointer border-[1px] border-[#5A5C20] rounded-sm px-3 py-1 mt-3 sm:mt-0 mx-1 sm:mx-0`}>
                    <p className={` ${isActive ? styles.secondFilterActiveText : styles.secondFilterText} inter-thin`}>{toUpperCase(filter.name)}</p>
                </div>
            </div>
            {isActive ? (
                <>
                    {filter.type === 'LIST' ? (
                        <ListFilter filter={filter} selectedItems={selectedItems} handleItemClick={handleItemClick} />
                    ) : null}

                    {filter.type === 'NUMBER' ? (
                        <NumberFilter filter={filter} counters={counters} increment={increment} decrement={decrement} mode={'secondary'} traveler={0} modified={0} />
                    ) : null}
                </>
            ) : null}
        </div>
    );
};




const Filter = ({ secondFilter }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedValues, setSelectedValues] = useState({});

    const handleSelect = (filterName, values) => {
        setSelectedValues((prevSelectedValues) => {
            const updatedValues = { ...prevSelectedValues };
            if (values.length > 0) {
                updatedValues[filterName] = values;
            } else {
                delete updatedValues[filterName];
            }
            return updatedValues;
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!document.getElementById('filter-container').contains(event.target)) {
                setActiveIndex(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    {/*useEffect(() => {
        console.log('liste de filtre :', selectedValues)
    }, [selectedValues])*/}



    return (
        <>
            <div id="filter-container" className={`${styles.paddingX} max-w-7xl flex`}>
                <div className='flex flex-wrap justify-center gap-0 sm:gap-2 pt-16'>
                    {secondFilter && secondFilter
                        .filter(filter => filter.status === 'SECOND')
                        .map((filter, index) => (
                            <div key={`address-${index}`}>
                                <FilterList
                                    key={index}
                                    filter={filter}
                                    isActive={activeIndex === index}
                                    onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                                    onSelect={(filterName, values) => {
                                        handleSelect(filterName, values);
                                    }}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default Filter;
