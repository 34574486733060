import React from 'react';
import { styles } from './../../styles';
import {logo} from '../../assets';
import { useTranslation } from 'react-i18next';


const LogoText = () => {
    const { t } = useTranslation();


    return (
        <div className={`flex flex-col justify-center`}>
            <img className='h-[60px] sm:h-[60px] md:h-[90px]' src={logo} alt="logo" />
            <p className={`${styles.heroHeadText} text-center w-full libre-baskerville-regular mt-5`}>
                {t('home.text.slogan.1')}<span className='libre-baskerville-regular-italic mx-2'>{t('home.text.slogan.2')}</span>{t('home.text.slogan.3')}<span className='libre-baskerville-regular-italic mx-2'>{t('home.text.slogan.4')}</span>
            </p>
        </div>
    )
}

export default LogoText
