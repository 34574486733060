import {

  location

} from "../assets";

const suggestions = () => [
    {
        image: location,
        name: 'Le Moulin',
        description: 'Soluptatur alignis cullantia nonsequatem exera sum faccate caepudandio tem facilla boreprehent recti odio. ',
    },
    {
        image: location,
        name: 'La Maison Bleue',
        description: 'Soluptatur alignis cullantia nonsequatem exera sum faccate caepudandio tem faignam exerita velicae. Accusa sum faccate caepudandio tem faignam exerita velicae. Accusap itaquat.',
    },
    {
        image: location,
        name: 'Château de la Loire',
        description: 'Soluptatur alignis cullantia nonsequatem exera sum faccate caepudandio tem facilla boreprehent recti odio.',
    },
    {
        image: location,
        name: 'Château de la Loire',
        description: 'Soluptatur alignis cullantia nonsequatem exera sum fapis eium delignam exerita velicae. Accusap itaquat.',
    }
];

const filter = () => [
    {
        type: 'INPUT',
        status:'FIRST',
        name: 'Destination',
        value: null
    },
    {
        type: 'NUMBER',
        status:'FIRST',
        name: 'Voyageurs',
        value: [
            {
                name:'Adulte',
                description:'18 ans et plus',
            },
            {
                name:'Enfants',
                description:'de 2 à 17 ans',
            },
            {
                name:'Bébés',
                description:'- de 2 ans',
            }
        ],
    },
    {
        type: 'LIST',
        status:'FIRST',
        name: 'Évènement',
        value: [
            'Grand hôtel (plus de 15 chambres)',
            'Hôtel intimiste',
            'Grande demeure (gîtes)',
            'Maison d’hôtes',
            'Appartement',
            'Cabane'
        ]
    },
    {
        type: 'LIST',
        status:'SECOND',
        name: 'Type de logement',
        value: [
            'Grand hôtel (plus de 15 chambres)',
            'Hôtel intimiste',
            'Grande demeure (gîtes)',
            'Maison d’hôtes',
            'Appartement',
            'Cabane'
        ]
    },
    {
        type: 'NUMBER',
        status:'SECOND',
        name: 'Chambres et lits',
        value: [
            {
                name:'Adulte',
                description:'18 ans et plus',
            },
            {
                name:'Enfants',
                description:'de 2 à 17 ans',
            },
            {
                name:'Bébés',
                description:'- de 2 ans',
            }
        ],
    },
    {
        type: 'LIST',
        status:'SECOND',
        name: 'Equipements',
        value: [
            'Grand hôtel (plus de 15 chambres)',
            'Hôtel intimiste',
            'Grande demeure (gîtes)',
            'Maison d’hôtes',
            'Appartement',
            'Cabane'
        ]
    },
    {
        type: 'LIST',
        status:'SECOND',
        name: 'Environnement',
        value: [
            'Grand hôtel (plus de 15 chambres)',
            'Hôtel intimiste',
            'Grande demeure (gîtes)',
            'Maison d’hôtes',
            'Appartement',
            'Cabane'
        ]
    },
    {
        type: 'LIST',
        status:'SECOND',
        name: 'Voyage responsable',
        value: [
            'Grand hôtel (plus de 15 chambres)',
            'Hôtel intimiste',
            'Grande demeure (gîtes)',
            'Maison d’hôtes',
            'Appartement',
            'Cabane'
        ]
    },
    {
        type: 'LIST',
        status:'SECOND',
        name: 'Les plus',
        value: [
            'Grand hôtel (plus de 15 chambres)',
            'Hôtel intimiste',
            'Grande demeure (gîtes)',
            'Maison d’hôtes',
            'Appartement',
            'Cabane'
        ]
    }
];


const addresses = () => [
    {
        id:'moulin',
        image: location,
        name: 'Le Moulin',
        description: 'Une maison de copain à 2h de Paris',
        location: 'Cogners',
        nb: 12,
        type: 'Grande Demeure',
        service: 'À partir de 800€ / nuit'
    },
    {
        id:'maison-bleue',
        image: location,
        name: 'La Maison Bleue',
        description: 'Un havre de paix en Bretagne',
        location: 'Marseille',
        nb: 6,
        type: 'Grande Demeure',
        service: 'À partir de 600€ / nuit'
    },
    {
        id:'chateau-loire',
        image: location,
        name: 'Château de la Loire',
        description: 'Un séjour royal au cœur des châteaux',
        location: 'Poitiers',
        nb: 18,
        type: 'Grande Demeure',
        service: 'À partir de 1200€ / nuit'
    },
    {
        id:'le-perche',
        image: location,
        name: 'Le Moulin',
        description: 'Une maison de copain à 2h de Paris',
        location: 'Cogners',
        nb: 12,
        type: 'Grande Demeure',
        service: 'À partir de 800€ / nuit'
    },
    {
        id:'la-maison',
        image: location,
        name: 'La Maison Bleue',
        description: 'Un havre de paix en Bretagne',
        location: 'Marseille',
        nb: 6,
        type: 'Grande Demeure',
        service: 'À partir de 600€ / nuit'
    },
    {
        id:'blicsjf',
        image: location,
        name: 'La Maison Bleue',
        description: 'Un havre de paix en Bretagne',
        location: 'Marseille',
        nb: 6,
        type: 'Grande Demeure',
        service: 'À partir de 600€ / nuit'
    },
];


export { addresses, suggestions, filter };
