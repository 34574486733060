import React from "react";
import { styles } from "../styles";
import { backFavorite, location } from "../assets";
import { useTranslation } from 'react-i18next';
import { suggestions } from "../constants";







const FavoriteCard = ({ image, name, description }) => {


    return (
        <div className="cursor-pointer sm:mt-6 md:mt-0 flex flex-col min-w-[266px] max-w-[266px] w-[266px] max-h-[600px] ">
            <div className="border-[1px] border-[#5A5C20] p-3 min-h-[450px]">
                <img src={image} alt="favorite addresse picture" className='mx-auto h-[242px] w-full object-cover' />
                <div className="pt-5 h-[180px]">
                    <p className={`${styles.secondaryTextColor} ${styles.locationTitle} inter-regular text-start pb-2`}>{name}</p>
                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start libre-baskerville-regular min-h-[80px]`}>
                        {description.length > 150 ? `${description.substring(0, 150)}...` : description}
                    </p>
                </div>
            </div>
        </div>
    );
};




const Favorite = () => {
    const { t } = useTranslation();
    const toUpperCase = (text) => {
        return text.toUpperCase();
    };

    const data = suggestions();



    return (
        <>
            <div className={`${styles.paddingY} flex flex-col justify-center items-center`}>

                <div className={`${styles.paddingX} mx-auto flex-col`}>
                    <div className="relative">
                        <img src={backFavorite} className="rounded-md" />
                        <h2 className={`${styles.primaryTextColor} ${styles.secondSectionTitle} inter-bold text-center absolute inset-0 flex justify-center items-end pb-3`}>
                            {toUpperCase(t('favorite.title'))}
                        </h2>
                    </div>

                    <div className="mx-auto justify-center items-center pt-8 pb-5">
                        <p className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} libre-baskerville-regular text-center`}>
                            {t('favorite.subtitle.1')}
                        </p>
                        <div className="flex flex-wrap justify-center items-center mt-2">
                            <p className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} libre-baskerville-regular text-center`}>
                                {t('favorite.subtitle.2')}
                            </p>
                            <p className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} libre-baskerville-regular-italic text-center mx-1`}>
                                {t('favorite.subtitle.3')}
                            </p>
                            <p className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} libre-baskerville-regular text-center `}>
                                {t('favorite.subtitle.4')}
                            </p>
                            <p className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} libre-baskerville-regular-italic text-center mx-1`}>
                                {t('favorite.subtitle.5')}
                            </p>
                        </div>
                    </div>

                </div>

                <div className={`${styles.paddingX} mx-auto`}>
                    <div className="flex flex-wrap items-center justify-center gap-10 sm:gap-10 md:gap-16">
                        {data.map((suggestion, index) => (
                            <FavoriteCard
                                key={index}
                                image={suggestion.image}
                                name={suggestion.name}
                                description={suggestion.description}
                                location={suggestion.location}
                                nb={suggestion.nb}
                                type={suggestion.type}
                                service={suggestion.service}
                            />
                        ))}
                    </div>
                    <button className="pt-20">
                        <p className={`${styles.secondaryTextColor} ${styles.sectionSubTitle} text-start libre-baskerville-regular pb-1`}>
                            {t('favorite.button')}
                        </p>
                        <hr className="border-t border-[#5A5C20]" />

                    </button>
                </div>
            </div>
        </>
    );
};

export default Favorite;
