import React, { useState, useEffect, useRef } from "react";
import { styles } from "../../styles";
import { useTranslation } from 'react-i18next';
import { geolocGreen, geolocGrey } from "../../assets";
import { filter } from "../../constants";
import ListSearch from "./ListSearch";
import NumberSearch from "./NumberSearch";
import SmNumberSearch from "./SmNumberSearch";
import SmListSearch from "./SmListSearch";






const Bar = ({
    mode,
    onClick,
    onUpdate,
    traveler,
    filter,
    onSelect,
    counters,
    newDestination,
    selectedItems,
    handleClickOutside,
    activeInput,
    handleFocus,
    showCounters,
    handleBlur,
    handleChangeDestination,
    toggleShowCounters,
    toggleShowList,
    increment,
    decrement,
    handleItemClick,
    showList,
    modified,
    searchBarRef }) => {
    const { t } = useTranslation();

    const firstLocationFilters = filter.filter(data => data.status === 'FIRST');

    useEffect(() => {
        const updatedCounters = counters.map(value => (value === 0 ? null : value));
        const hasNonZeroCounters = updatedCounters.some(value => value !== null);

        if (hasNonZeroCounters) {
            onSelect('Voyageurs', updatedCounters);
            onUpdate(newDestination, updatedCounters, selectedItems);
        } else if (selectedItems.length > 0) {
            onSelect('Evenement', selectedItems);
            onUpdate(newDestination, updatedCounters, selectedItems);
        } else if (newDestination) {
            onSelect('Destination', newDestination);
            onUpdate(newDestination, updatedCounters, selectedItems);
        }
    }, [counters, selectedItems, newDestination]);



    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={searchBarRef} className={`${mode === 'primary' ? 'border-[#FFFEFA] bg-[#FFFEFA] bg-opacity-20' : 'border-[#5A5C20]'} mx-auto flex border-[1px] justify-between rounded-full h-[60px] min-w-full`}>
                {filter.map((data, index) => (
                    <React.Fragment key={index}>
                        {data.status === 'FIRST' ? (
                            <>
                                {data.type === 'INPUT' ? (
                                    <div className="flex-auto flex justify-between items-center h-full">
                                        <div className='px-10 flex-col flex flex-auto justify-center'>
                                            {activeInput === 'destination' ? null : (
                                                <label
                                                    className={`${mode === 'primary' ? styles.heroFilterText : styles.heroFilterTextSecond} libre-baskerville-regular text-start`}>
                                                    {data.name}
                                                </label>
                                            )}
                                            <input
                                                type="text"
                                                placeholder={data.subname}
                                                className={`${mode === 'primary' ? styles.heroFilterText : styles.heroFilterTextSecond} bg-transparent outline-none inter-thin text-start`}
                                                onFocus={() => handleFocus('destination')}
                                                onBlur={handleBlur}
                                                value={newDestination}
                                                onChange={handleChangeDestination}
                                            />
                                        </div>
                                        <div className="h-full cursor-pointer flex flex-auto justify-center items-center">
                                            {mode === 'primary' ?
                                                <img className="h-[30px] mx-5" src={geolocGrey} />
                                                :
                                                <img className="h-[30px] mx-5" src={geolocGreen} />
                                            }
                                        </div>
                                    </div>
                                ) : null}
                                {data.type === 'NUMBER' ? (
                                    <NumberSearch
                                        mode={mode}
                                        showCounters={showCounters}
                                        onClick={toggleShowCounters}
                                        traveler={traveler}
                                        data={data}
                                        counters={counters}
                                        increment={increment}
                                        decrement={decrement}
                                        modified={modified} />
                                ) : null}
                                {data.type === 'LIST' ? (
                                    <ListSearch
                                        data={data}
                                        onClick={toggleShowList}
                                        selectedItems={selectedItems}
                                        handleItemClick={handleItemClick}
                                        showList={showList}
                                        mode={mode} />
                                ) : null}
                            </>
                        ) : null}
                        {data.status === 'FIRST' && !showCounters && (
                            <>
                                {showList
                                    ? (index < firstLocationFilters.length - 1 && index !== firstLocationFilters.length - 2) && (
                                        <hr className={`border-l ${styles.primaryBackColor} max-h-10 h-full my-auto`} />
                                    )
                                    : (index < firstLocationFilters.length - 1) && (
                                        <hr className={`border-l ${styles.primaryBackColor} max-h-10 h-full my-auto`} />
                                    )}
                            </>
                        )}
                    </React.Fragment>
                ))}
                <button onClick={onClick} className={`${mode === 'primary' ? 'bg-[#FFFEFA]' : 'bg-[#5A5C20]'} ${showList ? 'rounded-r-full' : 'rounded-full'} px-5 flex-col flex flex-1 justify-center h-full rounded-r-full min-w-[115px] items-center`}>
                    <div className={`${mode === 'primary' ? styles.heroFilterButton : styles.heroFilterButtonSecond} inter-regular`}>
                        {t('searchbar.button')}
                    </div>
                </button>
            </div>
        </>
    );
};

const SmallBar = ({
    mode,
    onClick,
    onUpdate,
    traveler,
    filter,
    onSelect,
    counters,
    newDestination,
    selectedItems,
    handleClickOutside,
    activeInput,
    handleFocus,
    showCounters,
    handleBlur,
    handleChangeDestination,
    toggleShowCounters,
    toggleShowList,
    increment,
    decrement,
    handleItemClick,
    showList,
    modified,
    searchBarRef
}) => {
    const { t } = useTranslation();


    useEffect(() => {
        const updatedCounters = counters.map(value => (value === 0 ? null : value));
        const hasNonZeroCounters = updatedCounters.some(value => value !== null);

        if (hasNonZeroCounters) {
            onSelect('Voyageurs', updatedCounters);
            onUpdate(newDestination, updatedCounters, selectedItems);
        } else if (selectedItems.length > 0) {
            onSelect('Evenement', selectedItems);
            onUpdate(newDestination, updatedCounters, selectedItems);
        } else if (newDestination) {
            onSelect('Destination', newDestination);
            onUpdate(newDestination, updatedCounters, selectedItems);
        }
    }, [counters, selectedItems, newDestination]);



    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    return (
        <div ref={searchBarRef} className={` max-w-7xl flex flex-col`}>
            <>
                {filter.length > 0 && filter.map((data, index) => (
                    <div key={index}>
                        {data.status === 'FIRST' ? (
                            <>
                                {data.type === 'INPUT' ? (
                                    <>
                                        <div key={index} className={`${mode === 'primary' ? 'border-[#FFFEFA]' : 'border-[#5A5C20]'} flex border-[1px] rounded-full h-[50px]`}>
                                            <div className='px-5 flex-col flex flex-1 justify-center h-full'>
                                                {activeInput === 'destination' ? null : (
                                                    <label
                                                        className={`${mode === 'primary' ? styles.heroFilterText : styles.heroFilterTextSecond} libre-baskerville-regular text-start`}>
                                                        {data.name}
                                                    </label>
                                                )}
                                                <input
                                                    type="text"
                                                    placeholder={data.subname}
                                                    className={`${mode === 'primary' ? styles.heroFilterText : styles.heroFilterTextSecond} bg-transparent outline-none inter-thin text-start`}
                                                    onFocus={() => handleFocus('destination')}
                                                    onBlur={handleBlur}
                                                    value={newDestination}
                                                    onChange={handleChangeDestination}
                                                />

                                            </div>
                                            <div className="h-full cursor-pointer flex justify-center items-center">
                                                {mode === 'primary' ?
                                                    <img className="h-[20px] mx-5" src={geolocGrey} />
                                                    :
                                                    <img className="h-[20px] mx-5" src={geolocGreen} />
                                                }
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                {data.type === 'NUMBER' ? (
                                    <div className={`${showCounters ? mode === 'primary' ? styles.primaryBackColor : styles.secondaryBackColor : null} ${mode === 'primary' ? 'border-[#FFFEFA]' : 'border-[#5A5C20]'} mt-4 border-[1px] rounded-full min-w-full h-[50px] flex items-center`}>
                                        <SmNumberSearch
                                            mode={mode}
                                            showCounters={showCounters}
                                            onClick={toggleShowCounters}
                                            traveler={traveler}
                                            data={data}
                                            counters={counters}
                                            increment={increment}
                                            decrement={decrement}
                                            modified={modified} />
                                    </div>
                                ) : null}

                                {data.type === 'LIST' ? (
                                    <div className={`${showList ? mode === 'primary' ? styles.primaryBackColor : styles.secondaryBackColor : null} ${mode === 'primary' ? 'border-[#FFFEFA]' : 'border-[#5A5C20]'} mt-4 border-[1px] w-full flex-1 rounded-full h-[50px] flex items-center`}>
                                        <SmListSearch
                                            data={data}
                                            onClick={toggleShowList}
                                            selectedItems={selectedItems}
                                            handleItemClick={handleItemClick}
                                            showList={showList}
                                            mode={mode} />
                                    </div>
                                ) : null}
                            </>
                        ) : null
                        }
                    </div>

                ))}
            </>
            <div className={`${mode === 'primary' ? 'bg-[#FFFEFA]' : 'bg-[#5A5C20]'} mt-4 px-5 flex-col flex flex-1 justify-center h-full rounded-full min-w-[115px] items-center`}>
                <button onClick={onClick} className={`${mode === 'primary' ? styles.heroFilterButton : styles.heroFilterButtonSecond} inter-regular  w-full h-[50px]`}>
                    {t('searchbar.button')}
                </button>
            </div>




        </div>

    );
};


const SearchBar = ({ mode, onClick, onUpdate, destination, event, traveler, filter }) => {

    const [screenWidth, setScreenWidth] = useState(0);
    const [selectedValues, setSelectedValues] = useState([]);
    const [newDestination, setNewDestination] = useState(destination || '');
    const [showCounters, setShowCounters] = useState(false);
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelectedItems] = useState(event || []);
    const [activeInput, setActiveInput] = useState(null);
    const searchBarRef = useRef(null);

    const [counters, setCounters] = useState(() => {
        const numberFilter = filter.find(f => f.status === 'FIRST' && f.type === 'NUMBER');

        if (numberFilter && Array.isArray(numberFilter.value)) {
            // Vérifier si traveler existe avant de l'utiliser
            if (traveler) {
                return numberFilter.value.map((value, index) => (traveler[index] ?? 0));
            } else {
                //console.warn("traveler is not defined or null, using default values.");
                return numberFilter.value.map(() => 0); // Utilisation de valeurs par défaut si traveler n'est pas défini
            }
        } else {
            //console.error("numberFilter.value is not an array or numberFilter is undefined");
            return [];
        }
    });

    const [modified, setModified] = useState(() => {
        if (filter.some(f => f.status === 'FIRST' && f.type === 'NUMBER' && Array.isArray(f.value))) {
            return filter.find(f => f.status === 'FIRST' && f.type === 'NUMBER').value.map(() => false);
        } else {
            return [];
        }
    });

    useEffect(() => {

        setScreenWidth(window.innerWidth);
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
        return () =>
            window.removeEventListener("resize", () =>
                setScreenWidth(window.innerWidth)
            );

    }, []);

    const handleSelect = (filterName, values) => {
        setSelectedValues((prevSelectedValues) => {
            const existingFilterIndex = prevSelectedValues.findIndex(item => item.filterName === filterName);

            if (existingFilterIndex !== -1) {
                // Si le filtre existe déjà, mettre à jour ses valeurs
                const updatedSelectedValues = [...prevSelectedValues];
                updatedSelectedValues[existingFilterIndex] = { filterName, values };
                return updatedSelectedValues;
            } else {
                // Sinon, ajouter le nouveau filtre avec ses valeurs
                return [...prevSelectedValues, { filterName, values }];
            }
        });
    };

    useEffect(() => {
        //console.log('SearchBar filter   :', selectedValues)
    }, [selectedValues])





    const handleFocus = (inputName) => {
        setActiveInput(inputName);
    };
    const handleBlur = () => {
        setActiveInput(null);
    };

    const handleChangeDestination = (e) => {
        const { value } = e.target;
        setNewDestination(value);
    };

    const increment = (index) => {
        setCounters((prevCounters) => {
            const newCounters = [...prevCounters];
            newCounters[index] = (newCounters[index] || 0) + 1;
            return newCounters;
        });
        setModified((prevModified) => {
            const newModified = [...prevModified];
            newModified[index] = true;
            return newModified;
        });
    };

    const decrement = (index) => {
        setCounters((prevCounters) => {
            const newCounters = [...prevCounters];
            newCounters[index] = Math.max(0, (newCounters[index] || 0) - 1);
            return newCounters;
        });
        setModified((prevModified) => {
            const newModified = [...prevModified];
            newModified[index] = true;
            return newModified;
        });
    };

    const handleItemClick = (item) => {
        setSelectedItems((prevSelectedItems) => {
            let updatedSelectedItems;
            if (prevSelectedItems.includes(item)) {
                updatedSelectedItems = prevSelectedItems.filter((i) => i !== item);
            } else {
                updatedSelectedItems = [...prevSelectedItems, item];
            }
            return updatedSelectedItems;
        });
    };

    const handleClickOutside = (event) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
            setShowCounters(false);
            setShowList(false);
        }
    };

    const toggleShowCounters = () => {
        setShowCounters(prevState => {
            if (!prevState) setShowList(false);
            return !prevState;
        });
    };

    const toggleShowList = () => {
        setShowList(prevState => {
            if (!prevState) setShowCounters(false);
            return !prevState;
        });
    };



    return (

        <>
            {screenWidth > 900 ? (
                <Bar
                    mode={mode}
                    onClick={onClick}
                    onUpdate={onUpdate}
                    destination={destination}
                    traveler={traveler}
                    event={event}
                    filter={filter}
                    onSelect={(filterName, values) => {
                        handleSelect(filterName, values)
                    }}
                    counters={counters}
                    newDestination={newDestination}
                    selectedItems={selectedItems}
                    handleClickOutside={handleClickOutside}
                    handleItemClick={handleItemClick}
                    activeInput={activeInput}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    handleChangeDestination={handleChangeDestination}
                    showCounters={showCounters}
                    showList={showList}
                    toggleShowCounters={toggleShowCounters}
                    toggleShowList={toggleShowList}
                    increment={increment}
                    decrement={decrement}
                    modified={modified}
                    searchBarRef={searchBarRef}

                />
            ) : (
                <SmallBar
                    mode={mode}
                    onClick={onClick}
                    onUpdate={onUpdate}
                    destination={destination}
                    traveler={traveler}
                    event={event}
                    filter={filter}
                    onSelect={(filterName, values) => {
                        handleSelect(filterName, values)
                    }}
                    counters={counters}
                    newDestination={newDestination}
                    selectedItems={selectedItems}
                    handleClickOutside={handleClickOutside}
                    handleItemClick={handleItemClick}
                    activeInput={activeInput}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    handleChangeDestination={handleChangeDestination}
                    showCounters={showCounters}
                    showList={showList}
                    toggleShowCounters={toggleShowCounters}
                    toggleShowList={toggleShowList}
                    increment={increment}
                    decrement={decrement}
                    modified={modified}
                    searchBarRef={searchBarRef}
                />

            )}

        </>

    );
};

export default SearchBar;
