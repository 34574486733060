import React from "react";
import { styles } from '../../styles';
import { moins, plus, moinsFirst, plusFirst } from "../../assets";
import { useTranslation } from 'react-i18next';


const SmNumberSearch = ({ data, counters, increment, decrement, modified, mode, traveler, showCounters, onClick }) => {
    const { t } = useTranslation();


    return (
        <div>
            <div onClick={onClick} className={` ${mode === 'primary' ? styles.primaryTextColor : styles.secondaryTextColor} pl-5 flex-col flex-1 flex justify-center min-w-[300px] rounded-full`}>
                <label className={`${mode === 'primary' ? showCounters ? styles.heroFilterTextSecond : styles.heroFilterText : showCounters ? styles.heroFilterText : styles.heroFilterTextSecond} libre-baskerville-regular rounded-l-full text-start`}>
                    {data.name}
                </label>
                <p className={`${mode === 'primary' ? showCounters ? styles.heroFilterTextSecond : styles.heroFilterText : showCounters ? styles.heroFilterText : styles.heroFilterTextSecond} bg-transparent outline-none inter-thin text-start`}>
                    {data.subname}</p>
            </div>
            {showCounters && (
                <div className={`${mode === 'primary' ? styles.primaryBackColor : styles.secondaryBackColor} absolute flex flex-col rounded-sm p-3 mt-2`}>
                    {data.number_values.map((value, index) => (
                        <div key={index} className='flex justify-between items-center min-w-full pt-3 gap-3'>
                            <div className='flex'>
                                <p className={`${styles.regularText} ${mode === 'primary' ? styles.secondaryTextColor : styles.primaryTextColor} inter-thin`}>{value.name}</p>
                            </div>
                            <p className={`${mode === 'primary' ? styles.secondaryTextColor : styles.primaryTextColor} ${styles.smText} inter-extra-thin`}>{value.description}</p>
                            <div className='flex items-end justify-end'>
                                <div className='flex gap-3 items-center'>
                                    <div onClick={() => decrement(index)} className={`${mode === 'primary' ? 'border-[#5A5C20]' : 'border-[#FFFEFA]'} rounded-full border-[1px] h-5 w-5 flex items-center justify-center`}>
                                        <img src={`${mode === 'primary' ? moins : moinsFirst}`} />
                                    </div>
                                    <p className={`${mode === 'primary' ? styles.secondaryTextColor : styles.primaryTextColor} ${styles.regularText} w-[10px] inter-thin`}>
                                        {modified[index] ?
                                            (counters && counters[index] !== undefined ? counters[index] : 0)
                                            :
                                            (traveler && traveler[index] !== undefined ? traveler[index] : 0)
                                        }
                                    </p>
                                    <div onClick={() => increment(index)} className={`${mode === 'primary' ? 'border-[#5A5C20]' : 'border-[#FFFEFA]'} rounded-full border-[1px] h-5 w-5 flex items-center justify-center`}>
                                        <img src={`${mode === 'primary' ? plus : plusFirst}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            )}
        </div>




    );
}

export default SmNumberSearch;



