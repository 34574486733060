
import '../App.css';
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { styles } from "../styles";
import SummaryList from '../components/AddressDetail/SummaryList';
import Bedrooms from '../components/AddressDetail/Bedrooms';
import Equipments from '../components/AddressDetail/Equipments';
import axiosInstance from '../context/axiosConfig';


function AddressDetailPage() {
    const [locationData, setLocationData] = useState([]);
    const { state } = useLocation();
    const { data } = state || {};
    const toUpperCase = (text) => {
        return text.toUpperCase();
    };

    useEffect(() => {
        axiosInstance.get(`/api/addresses/${data.id}/`)
            .then(res => {
                setLocationData(res.data);
            })
            .catch(err => {
                console.error('error   : ', err);
            });
    }, [data]);


    return (
        <>
            <div className="App flex-col">
                <div className="relative h-[700px] w-full">
                    <img src={locationData.big_picture} alt="background address picture" className='absolute top-0 left-0 h-[700px] w-full object-cover' />
                    <div className={`flex flex-col relative h-full w-full items-center justify-center md:items-end md:justify-end py-10`}>
                        <h1 className={`${styles.primaryTextColor} ${styles.sectionTitle}  inter-bold mx-10 text-center md:text-start`}>
                            {locationData.name ? (
                                <>
                                    {toUpperCase(locationData.name)}
                                </>
                            ) : null}
                        </h1>
                        {locationData.address && locationData.city && locationData.postalCode ? (
                            <p className={`${styles.primaryTextColor} ${styles.sectionSubTitle} libre-baskerville-regular mx-10 text-center md:text-start`}>
                                {locationData.address + ' - ' + locationData.postalCode + ' ' + locationData.city + ', ' + locationData.country}
                            </p>
                        ) : null}
                    </div>
                </div>
                <div className={`${styles.paddingX}`}>
                    <SummaryList data={locationData} />
                </div>
                <Bedrooms data={locationData} />
                <div className={`${styles.paddingX}`}>

                    <Equipments data={locationData} />
                </div>

            </div>
        </>
    );
}

export default AddressDetailPage;
